import React, {useEffect, useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion'

let dataSheetsKeypad = [
  {
    "title": "Aspire | Keypad | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/k0Fr1Qcoi0GauC2VFHEGe/b663d7f3ed9ba425d7df416ba06a8ac7/DS-NMSK-AD-DEN.pdf",
      "fileName": "DS-NMSK-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Keypad | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/25HzX1lFm2FOsjOWmiB06K/af3ff69391141156d6cecec19e2acb7b/DS-NMSK-AP-DEN.pdf",
      "fileName": "DS-NMSK-AP-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesKeypad = [
  {
    "title": "Aspire | Keypad & Touch RFID | 4G Motorized Rear Unit",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/49RQkqiUR8Vl7s5NI6OWEA/79748525fb48940b5ea6865b1031cac6/IG-NM-A-4Gr-DEN.pdf",
      "fileName": "IG-NM-A-4Gr-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Keypad & Touch RFID | 5G Motorized Rear Unit",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/2aOj1fhyDSLm2Z0PNrIwR9/bba849f76b530f79c2e44f269d5424b2/IG-NM-A-5Gr-DEN.pdf",
      "fileName": "IG-NM-A-5Gr-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesKeypad = [
  {
    "title": "Aspire | Keypad | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/3cpbuhZ0qea2cLkTznvPZo/22dc55e27a60fe5eef636bc9a66c075c/PG-NMSK-AD-DEN.pdf",
      "fileName": "PG-NMSK-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Keypad | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/1TCKdyNAtehVscadptGpGh/33f749336c98606dca35b8e746178b28/PG-NMSK-AP-DEN.pdf",
      "fileName": "PG-NMSK-AP-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

let dataSheetsRFID = [
  {
    "title": "Aspire | Touch RFID | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5VYpvPbqoY3R32n4TcQYKk/31a9c09d5f9c2893e116860c114add55/DS-NMTR-AD-DEN.pdf",
      "fileName": "DS-NMTR-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Touch RFID | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6uPTXqY2wtxuqOhzt4SruK/f3345fb054e8ecddd0452a776949f298/DS-NMTR-AP-DEN.pdf",
      "fileName": "DS-NMTR-AP-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesRFID = [
  {
    "title": "Aspire | Keypad & Touch RFID | 4G Motorized Rear Unit",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/49RQkqiUR8Vl7s5NI6OWEA/79748525fb48940b5ea6865b1031cac6/IG-NM-A-4Gr-DEN.pdf",
      "fileName": "IG-NM-A-4Gr-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Keypad & Touch RFID | 5G Motorized Rear Unit",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/2aOj1fhyDSLm2Z0PNrIwR9/bba849f76b530f79c2e44f269d5424b2/IG-NM-A-5Gr-DEN.pdf",
      "fileName": "IG-NM-A-5Gr-DEN.pdf",
      "contentType": "application/pdf"
    }
  }

];
let productGuidesRFID = [
  {
    "title": "Aspire | Touch RFID | Deadbolt | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/16Xl7YTpNCrn07zXDJY3Bs/ea1e8e0afd89c030b12631b0c91e1206/PG-NMTR-AG-DEN.pdf",
      "fileName": "PG-NMTR-AG-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Touch RFID | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3CYJiqFMKk7NjLHdD7TXCu/6d07a2664f6ef9899681c5cb0e5499f8/PG-NMTR-AP-DEN.pdf",
      "fileName": "PG-NMTR-AP-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  useEffect(() => {
    if (document.location.hash === '#rfid') {
      setToggleState(2);
    }
  }, [])

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Numeris Aspire <Trans>keypad</Trans>
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            Numeris Aspire <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'aspire-locks.png'}
                  loading={'lazy'}
                  width={283}
                  height={531}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Electronic Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Units"
                  style={{transform:'scale(.8)'}}
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className={"tab-content "+getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'aspire-locks-rfid.png'}
                  loading={'lazy'}
                  width={283}
                  height={531}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Electronic Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Units"
                  style={{transform:'scale(.8)'}}
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsRFID.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Tab;